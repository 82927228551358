import type { SendStatus } from '~/types'

export default function useClaimStatus () {
  const _status = useState<SendStatus | undefined>('useClaimStatus-status', () => undefined)
  const _error = useState<string | undefined>('useClaimStatus-error', () => undefined)

  const setStatus = (status: SendStatus) => {
    _status.value = status
  }

  const setError = (error: string) => {
    _error.value = error
  }

  return {
    claimStatus: readonly(_status),
    claimError: readonly(_error),
    setStatus,
    setError,
  }
}
